import React from "react";
import { graphql } from 'gatsby';

const pageStyles = {
  fontSize: "3.5em",
  padding: 96,
  fontFamily: "Helvetica, Roboto, sans-serif, serif",
}

const IndexPage = (props) => {
  const { brandName } = props.data.graphCmsSpaceSite;
  return (
    <main style={pageStyles}>
    <title>Space Apps</title>
      <h1>{brandName}</h1>
    </main>
  )
}

export default IndexPage

export const BrandTitle = graphql`
    {
      graphCmsSpaceSite(brandName: {}) {
        brandName
      }
    }
`;
